import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { getBase64 } from "../../Helper/Base64File";
import Inefficiency from "../../components/UserProfileView/Checklist/Inefficiency.jsx";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";
import { SplitButton } from "primereact/splitbutton";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  remarkStatus,
  getSeverity,
  modeOfVerification,
} from "../../utils/constants.js";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import NoData from "../../Helper/NoDataFound.jsx";
export const PoliceDetails = ({
  userVerificationDetail,
  userVerificationRequestInfo,
  taskDetail,
  policeCheckInfo,
  getPoliceCheckInfo,
  activityDetails,
  showActivity,
  showReWork,
  getActivityDetails,
  flag,
}) => {
  let user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [fileUpload, SetFileUplaod] = useState([]);
  const [uploadProof, SetUploadProof] = useState([]);
  const [photoUploads, setPhotoUploads] = useState([]);
  const [signUploads, setSignUploads] = useState([]);
  const [addressUploads, setAddressUploads] = useState([]);
  const [photoIdUploads, setPhotoIdUploads] = useState([]);
  const [dateOfBirthUploads, setDateOfBirthUploads] = useState([]);
  const [fileMap, setFileMap] = useState({});
  const [proofImage, setProofImage] = useState({});
  const [photoMap, setPhotoMap] = useState({});
  const [signMap, setSignMap] = useState({});
  const [addressMap, setAddressMap] = useState({});
  const [photoIdMap, setPhotoIdMap] = useState({});
  const [dobMap, setDobMap] = useState({});
  const [timer, setTimer] = useState(null);

  const [activity, setActivity] = useState("");
  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  let localStorageData = JSON.parse(localStorage.getItem("user"));

  const updateCaseIsQCOpen = async (flag) => {
    let body = {
      status: flag,
    };
    ApiService.post(`userVerificationRequest/${userVerificationDetail}`, body)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const confirm1 = (id) => {
    if (localStorageData?.role === "user") {
      setIsModalOpen(true);
    }
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      // reject: () => setDialogVisible(false),
      // onHide: () => setDialogVisible(false),
    });
  };

  const accept = (id) => {
    deleteItem(id);
  };

  const deleteItem = (id) => {
    const url = `policeCheck/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        // setDialogVisible(false);
        toast.success("Item Deleted Successfully");
        getPoliceCheckInfo(userVerificationDetail);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Delete Item");
      });
  };

  const createActivity = async (action, caseId) => {
    let body = {
      userVerificationRequestId: caseId,
      // checkId: checkId,
      FEName:
        localStorageData?.role === "BCA"
          ? localStorageData?.name
          : localStorageData?.role === "BCAStaff"
            ? `${localStorageData?.firstName} ${localStorageData?.lastName}`
            : localStorageData?.role === "Coordinator"
              ? localStorageData?.vendorName
              : localStorageData?.role === "DataEntry"
                ? localStorageData?.vendorName
                : "",
      checkName: "Police",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {
        getActivityDetails(userVerificationDetail);
        setActivity("");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  useEffect(() => {}, [fileUpload]);

  // const updateTaskStatus = async () => {
  //   let body = {
  //     status: "Completed",
  //   };
  //   await ApiService.post(`task/status/${taskDetail?._id}`, body)
  //     .then(() => {
  //       toast.success("Task Submitted Successfully");
  //       navigate("/staff-member-dashboard");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const handleSaveClick = (e, id, index) => {
    e.preventDefault();
    updateUANDetails(e, id, index);
    handleTaskForReWork();
  };

  const updateTaskStatus = async (taskId, status, flag) => {
    let body = {
      status: status,
    };
    await ApiService.post(`task/status/${taskId}`, body)
      .then(() => {
        if (status === "Completed") {
          toast.success("Task Submitted Successfully");
          updateCaseIsQCOpen(flag);
          navigate("/staff-member-dashboard");
        } else if (status === "Rework") {
          updateCaseIsQCOpen(flag);
          // navigate("/cases");
          toast.success("Task Updated Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  const handleSubmitTask = () => {
    updateTaskStatus(taskDetail?._id, "Completed", "QC-Open");
  };

  const handleTaskForReWork = () => {
    updateTaskStatus(userVerificationRequestInfo, "Rework", "Rework-WIP");
  };

  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "education",
  //   };
  //   await ApiService.post(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {
  //       // toast.success("Task Item Updated Successfully");
  //       // navigate("/staff-member-dashboard");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const removeImg = (e, id, item, reportIndex, type) => {
    e.preventDefault();

    let updatedUrls;
    switch (type) {
      case "file":
        updatedUrls = item?.police?.reportUrl.filter(
          (_, index) => index !== reportIndex
        );
        updatePoliceFileArray(e, id, item, updatedUrls);
        break;
      case "uploadProof":
        updatedUrls = item?.police?.uploadProofUrl.filter(
          (_, index) => index !== reportIndex
        );
        updatePoliceProofArray(e, id, item, updatedUrls);
        break;
      case "photo":
        updatedUrls = item?.police?.photo.filter(
          (_, index) => index !== reportIndex
        );
        updatePolicePhotoArray(e, id, item, updatedUrls);
        break;
      case "sign":
        updatedUrls = item?.police?.sign.filter(
          (_, index) => index !== reportIndex
        );
        updatePoliceSignArray(e, id, item, updatedUrls);
        break;
      case "addressUpload":
        updatedUrls = item?.police?.addressUpload.filter(
          (_, index) => index !== reportIndex
        );
        updatePoliceAddressUploadArray(e, id, item, updatedUrls);
        break;
      case "photoId":
        updatedUrls = item?.police?.photoId.filter(
          (_, index) => index !== reportIndex
        );
        updatePolicePhotoIdArray(e, id, item, updatedUrls);
        break;
      case "dateOfBirth":
        updatedUrls = item?.police?.dateOfBirth.filter(
          (_, index) => index !== reportIndex
        );
        updatePoliceDateOfBirthArray(e, id, item, updatedUrls);
        break;
      default:
        break;
    }
  };

  const updatePoliceFileArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "policeCheck/link";
    } else {
      url = "policeCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      police: {
        ...item.police,
        reportUrl: updatedReportUrls,
        remark: item.police.remark,
        uploadProofUrl: [
          ...new Set([...item.police.uploadProofUrl, ...uploadProof]),
        ],
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      reason: {
        ...item.reason,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getPoliceCheckInfo(userVerificationDetail);
      toast.success("Police details updated successfully");
      SetFileUplaod([]);
      SetUploadProof([]);
      if (flag === "false") {
        getPoliceCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updatePoliceProofArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "policeCheck/link";
    } else {
      url = "policeCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      police: {
        ...item.police,
        reportUrl: [...new Set([...item.police.reportUrl, ...fileUpload])],
        remark: item.police.remark,
        uploadProofUrl: updatedReportUrls,
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      reason: {
        ...item.reason,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getPoliceCheckInfo(userVerificationDetail);
      toast.success("Police details updated successfully");
      SetFileUplaod([]);
      SetUploadProof([]);
      if (flag === "false") {
        getPoliceCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updatePolicePhotoArray = async (e, id, item, updatedPhotoUrls) => {
    await updatePoliceDetails(e, id, item, {
      ...item.police,
      photo: updatedPhotoUrls,
    });
  };
  const updatePoliceSignArray = async (e, id, item, updatedSignUrls) => {
    await updatePoliceDetails(e, id, item, {
      ...item.police,
      sign: updatedSignUrls,
    });
  };
  const updatePoliceAddressUploadArray = async (
    e,
    id,
    item,
    updatedAddressUploadUrls
  ) => {
    await updatePoliceDetails(e, id, item, {
      ...item.police,
      addressUpload: updatedAddressUploadUrls,
    });
  };
  const updatePolicePhotoIdArray = async (e, id, item, updatedPhotoIdUrls) => {
    await updatePoliceDetails(e, id, item, {
      ...item.police,
      photoId: updatedPhotoIdUrls,
    });
  };

  const updatePoliceDateOfBirthArray = async (
    e,
    id,
    item,
    updatedDateOfBirthUrls
  ) => {
    await updatePoliceDetails(e, id, item, {
      ...item.police,
      dateOfBirth: updatedDateOfBirthUrls,
    });
  };

  const updatePoliceDetails = async (e, id, item, updatedPoliceData) => {
    let url =
      process.env.REACT_APP_PUBLIC_USER_LINK === "true"
        ? "policeCheck/link"
        : "policeCheck";
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      police: updatedPoliceData,
      verificationDetails: {
        ...item.verificationDetails,
      },
      reason: {
        ...item.reason,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getPoliceCheckInfo(userVerificationDetail);
      toast.success("Police details updated successfully");
      // Reset file upload states after successful update
      SetFileUplaod([]);
      SetUploadProof([]);
      setPhotoUploads([]);
      setSignUploads([]);
      setAddressUploads([]);
      setPhotoIdUploads([]);
      setDateOfBirthUploads([]);
      if (flag === "false") {
        getPoliceCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const onInputChange = (e, index, type, field) => {
    const { value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        // Create a copy of the original data array
        const newData = [...prevData];
        // Update the specific object at the given index
        if (field === "police") {
          newData[index] = {
            ...newData[index],
            police: {
              ...newData[index].police,
              [e.target.name]: e.target.value,
            },
          };
        } else if (field === "verificationDetails") {
          newData[index] = {
            ...newData[index],
            verificationDetails: {
              ...newData[index].verificationDetails,
              [e.target.name]: e.target.value,
            },
          };
        } else if (field === "reason") {
          newData[index] = {
            ...newData[index],
            reason: {
              ...newData[index].reason,
              [e.target.name]: e.target.value,
            },
          };
        }

        return newData;
      });
    }
  };

  useEffect(() => {
    setData(policeCheckInfo);
  }, [policeCheckInfo]);
  useEffect(() => {
    console.log(data, "<<<DATA>>>>");
  }, [data]);

  const updateUANDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "policeCheck/link";
    } else {
      url = "policeCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      police: {
        ...data[index]?.police,
        reportUrl: [
          ...new Set([...data[index]?.police?.reportUrl, ...fileUpload]),
        ],
        remark: data[index]?.police?.remark,
        uploadProofUrl: [
          ...new Set([...data[index]?.police?.uploadProofUrl, ...uploadProof]),
        ],
        photo: [...new Set([...data[index]?.police?.photo, ...photoUploads])],
        sign: [...new Set([...data[index]?.police?.sign, ...signUploads])],
        addressUpload: [
          ...new Set([
            ...data[index]?.police?.addressUpload,
            ...addressUploads,
          ]),
        ],
        photoId: [
          ...new Set([...data[index]?.police?.photoId, ...photoIdUploads]),
        ],
        dateOfBirth: [
          ...new Set([
            ...data[index]?.police?.dateOfBirth,
            ...dateOfBirthUploads,
          ]),
        ],
      },
      verificationDetails: {
        ...data[index]?.verificationDetails,
      },
      reason: {
        ...data[index]?.reason,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      // createActivity(
      //   remarkForActivity,
      //   userVerificationDetail,
      //   policeCheckInfo[0]._id
      // );

      toast.success("Police details updated successfully");
      SetFileUplaod([]);
      SetUploadProof([]);
      setPhotoUploads([]);
      setSignUploads([]);
      setAddressUploads([]);
      setPhotoIdUploads([]);
      setDateOfBirthUploads([]);
      if (flag === "false") {
        getPoliceCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateCheckStatus = async (status, id) => {
    // e.preventDefault();
    const body = { status: status };
    try {
      await ApiService.post(`policeCheck/police/status/${id}`, body);
      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      getPoliceCheckInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };

  const handleEdit = () => {
    setEditable(!editable);
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 10) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });
      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
        case "file":
          SetFileUplaod(uploadedFileUrls);
          toast.success("File Upload successfully!");
          break;
        case "uploadProof":
          SetUploadProof(uploadedFileUrls);
          toast.success("File Upload successfully!");
          break;
        case "photo":
          setPhotoUploads(uploadedFileUrls);
          toast.success("Photo Upload successfully!");
          break;
        case "sign":
          setSignUploads(uploadedFileUrls);
          toast.success("Sign Upload successfully!");
          break;
        case "addressUpload":
          setAddressUploads(uploadedFileUrls);
          toast.success("Address Upload successfully!");
          break;
        case "photoId":
          setPhotoIdUploads(uploadedFileUrls);
          toast.success("Photo Id Upload successfully!");
          break;
        case "dateOfBirth":
          setDateOfBirthUploads(uploadedFileUrls);
          toast.success("DOB Upload successfully!");
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };

  const clearImages = () => {
    setFileMap({});
    setProofImage({});
    setPhotoMap({});
    setSignMap({});
    setAddressMap({});
    setPhotoIdMap({});
    setDobMap({});
    toast.warning("Link expired, please download again.");
  };

  const getImg = (e, fileName, dataIndex, reportIndex, imgType) => {
    e.preventDefault();

    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        switch (imgType) {
          case "photo":
            setPhotoMap((prev) => ({
              ...prev,
              [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
            }));
            break;
          case "sign":
            setSignMap((prev) => ({
              ...prev,
              [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
            }));
            break;
          case "address":
            setAddressMap((prev) => ({
              ...prev,
              [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
            }));
            break;
          case "photoId":
            setPhotoIdMap((prev) => ({
              ...prev,
              [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
            }));
            break;
          case "dob":
            setDobMap((prev) => ({
              ...prev,
              [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
            }));
            break;
          case "img":
            setFileMap((prev) => ({
              ...prev,
              [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
            }));
            break;
          case "proof":
            setProofImage((prev) => ({
              ...prev,
              [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
            }));
            break;
        }

        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME);
        setTimer(newTimer);
        toast.success("Document downloaded successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error in image download");
      });
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              createActivity(
                "Police Task Submitted!",
                userVerificationDetail,
                policeCheckInfo[0]._id
              );
              handleSubmitTask();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}

      {showReWork &&
        data?.map((item, dataIndex) =>
          dataIndex === 0 ? (
            <div
              key={dataIndex}
              className="border rounded shadow-md my-4 bg-[#fafafa]"
            >
              <div className="grid items-center sm:grid-cols-3 gap-x-4 mt-2 p-3">
                <div className="col-span-2">
                  <label
                    className="small mb-1 font-bold"
                    htmlFor="reWorkRemark"
                  >
                    Remark For Re-Work
                  </label>
                  <InputTextarea
                    className="form-control"
                    type="text"
                    name="reWorkRemark"
                    onChange={(e) =>
                      onInputChange(
                        e,
                        dataIndex,
                        "alphanumericWithAllowedSymbols",
                        "police"
                      )
                    }
                    value={item?.police?.reWorkRemark || ""}
                    // disabled={
                    //   flag === "true" || localStorageData?.role === "BCAStaff"
                    // }
                    rows={5}
                    cols={30}
                  />
                </div>
                {taskDetail?.status !== "InProgress" ? (
                  <div className="mt-[26px]">
                    <Button
                      className="w-[100px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      label="Submit"
                      type="submit"
                      loading={loading}
                      onClick={(e) => handleSaveClick(e, item._id, dataIndex)}
                      disabled={
                        flag === "true" || localStorageData?.role === "BCAStaff"
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
                {/* {taskDetail?.status !== "InProgress" ? (
                  <div className="mt-[26px]">
                    <Button
                      label="Submit"
                      type="button"
                      loading={loading}
                      onClick={() => handleTaskForReWork()}
                      className="w-[100px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      disabled={
                        flag === "true" || localStorageData?.role === "BCAStaff"
                      }
                    />
                  </div>
                ) : (
                  <></>
                )} */}
              </div>
              <div className="px-3 py-4 mt-4 bg-white shadow-md rounded">
                <label
                  className="small mb-1 font-bold"
                  htmlFor="submittedRemark"
                >
                  Submitted Remark:
                </label>
                <p className="text-gray-800 mt-2">
                  {item.police?.reWorkRemark || "No remark submitted"}
                </p>
              </div>
            </div>
          ) : null
        )}

      {showActivity && (
        <div className="border  rounded shadow-md my-4 bg-[#fafafa]">
          <div className="grid items-center sm:grid-cols-3 gap-x-4 mt-2 p-3 ">
            <div className="col-span-2">
              <label className="small mb-1 font-bold" htmlFor="activity">
                Activity
              </label>
              <textarea
                className="form-control h-[60px] "
                name="activity"
                onChange={(e) => {
                  setActivity(e.target.value);
                  // onInputChange(e, dataIndex, "alphanumeric");
                }}
                value={activity || ""}
              />
            </div>
            <div className="mt-[26px]">
              <Button
                label="Save"
                type="submit"
                loading={loading}
                onClick={(e) => {
                  e.preventDefault();
                  createActivity(
                    activity,
                    userVerificationDetail
                    // item?._id
                  );
                }}
                className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />
            </div>
          </div>
          <div className="">
            <label className="small mb-1 px-3   font-bold" htmlFor="activity">
              All Activities List
            </label>
            <div className="h-[310px] p-3 overflow-y-scroll">
              <List
                sx={{
                  width: "100%",
                  // maxWidth: 450,
                  bgcolor: "#fafafa",
                }}
              >
                {activityDetails.length === 0 ? (
                  <>
                    <div className="flex items-center lg:ml-[50px] mt-[30px]">
                      <NoData info="Ooops, we could not find any activity" />
                    </div>
                  </>
                ) : (
                  <>
                    {activityDetails.map((item) => {
                      const createdAt = new Date(item?.createdAt);
                      const formattedDate =
                        createdAt.toLocaleDateString("en-GB");
                      const formattedTime =
                        createdAt.toLocaleTimeString("en-GB");

                      return (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            className="border rounded !mb-3 shadow-md bg-[#ffffff]"
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt="Remy Sharp"
                                src="http://bootdey.com/img/Content/avatar/avatar1.png"
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Box className="flex flex-wrap item-center justify-between wrap">
                                  <Typography
                                    component="span"
                                    sx={{ fontWeight: "600" }} // Apply bold style here
                                    className="text-black text-wrap"
                                  >
                                    {item.FEName}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    className="!font-bold text-black mt-1"
                                  >
                                    {formattedDate} {formattedTime}
                                  </Typography>
                                </Box>
                              }
                              className="!font-bold text-black"
                              secondary={
                                <React.Fragment>
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "inline",
                                      color: "blue-300",
                                      marginRight: 1,
                                    }}
                                  >
                                    <Typography
                                      component="span"
                                      sx={{ color: "green" }}
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      Activity -
                                    </Typography>
                                  </Box>
                                  {item?.description}
                                  <br />
                                  {/* <Typography
                                sx={{ display: "inline", color: "green" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item?.activityDetails?.action}
                              </Typography> */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          {/* <Divider variant="inset" component="li" /> */}
                        </>
                      );
                    })}
                  </>
                )}
              </List>
            </div>
          </div>{" "}
        </div>
      )}

      {data?.map((item, dataIndex) => (
        <>
          <Card
            title={item?.qualification}
            className={`sm:border-2 ${dataIndex === 0 ? "mt-0" : "mt-4"} relative`}
          >
            {localStorageData?.role !== "BCAStaff" && (
              <div
                className=" text-end cursor-pointer absolute right-[-11px] top-[-13px] "
                role="presentation"
                onClick={() => confirm1(item._id)}
              >
                <DeleteIcon
                  style={{ color: "red", height: "35px", width: "35px" }}
                />
                {/* <ConfirmDialog /> */}
              </div>
            )}
            <div className="flex flex-col justify-end">
              {(() => {
                if (process.env.REACT_APP_IS_PAMAC_REPORT_ENABLED === "true") {
                  let user = JSON.parse(localStorage.getItem("user"));
                  const allowedRoles = [
                    "BCAStaff",
                    "BCA",
                    "Coordinator",
                    "Auditor",
                  ];

                  return (
                    <>
                      <div className="flex flex-row justify-between mb-[10px]">
                        <div className="flex justify-between">
                          {process.env.REACT_APP_SHOW_PAMAC_COLUMNS ===
                            "true" &&
                            allowedRoles.includes(user?.role) && (
                              <>
                                <SplitButton
                                  label="Update Status"
                                  icon="pi pi-check"
                                  model={[
                                    {
                                      label: "Major Discrepancy",
                                      command: () => {
                                        createActivity(
                                          "Pending -> Major Discrepancy",
                                          item?.userVerificationRequestId,
                                          item?._id
                                        );
                                        updateCheckStatus(
                                          "Major Discrepancy",

                                          item?._id,
                                          dataIndex
                                        );
                                      },
                                    },
                                    {
                                      label: "Minor Discrepancy",
                                      command: () => {
                                        createActivity(
                                          "Pending -> Minor Discrepancy",
                                          item?.userVerificationRequestId,
                                          item?._id
                                        );
                                        updateCheckStatus(
                                          "Minor Discrepancy",

                                          item?._id,
                                          dataIndex
                                        );
                                      },
                                    },
                                    {
                                      label: "Verified Clear",
                                      command: () => {
                                        createActivity(
                                          "Pending -> Verified Clear",
                                          item?.userVerificationRequestId,
                                          item?._id
                                        );
                                        updateCheckStatus(
                                          "Verified Clear",

                                          item?._id,
                                          dataIndex
                                        );
                                      },
                                    },
                                    {
                                      label: "Insufficiency",
                                      command: () => {
                                        createActivity(
                                          "Pending -> Insufficiency",
                                          item?.userVerificationRequestId,
                                          item?._id
                                        );
                                        updateCheckStatus(
                                          "Insufficiency",

                                          item?._id,
                                          dataIndex
                                        );
                                      },
                                    },
                                    {
                                      label: "Unable to Verify",
                                      command: () => {
                                        createActivity(
                                          "Pending -> Unable to Verify",
                                          item?.userVerificationRequestId,
                                          item?._id
                                        );
                                        updateCheckStatus(
                                          "Unable to Verify",

                                          item?._id,
                                          dataIndex
                                        );
                                      },
                                    },
                                    {
                                      label: "Stop Check",
                                      command: () => {
                                        createActivity(
                                          "Pending -> Stop Check",
                                          item?.userVerificationRequestId,
                                          item?._id
                                        );
                                        updateCheckStatus(
                                          "Stop Check",

                                          item?._id,
                                          dataIndex
                                        );
                                      },
                                    },
                                    {
                                      label: "Positive",
                                      command: () => {
                                        createActivity(
                                          "Pending -> Positive",
                                          item?.userVerificationRequestId,
                                          item?._id
                                        );
                                        updateCheckStatus(
                                          "Positive",

                                          item?._id,
                                          dataIndex
                                        );
                                      },
                                    },
                                    {
                                      label: "Negative",
                                      command: () => {
                                        createActivity(
                                          "Pending -> Negative",
                                          item?.userVerificationRequestId,
                                          item?._id
                                        );
                                        updateCheckStatus(
                                          "Negative",

                                          item?._id,
                                          dataIndex
                                        );
                                      },
                                    },
                                    {
                                      label: "Recommended",
                                      command: () => {
                                        createActivity(
                                          "Pending -> Recommended",
                                          item?.userVerificationRequestId,
                                          item?._id
                                        );
                                        updateCheckStatus(
                                          "Recommended",

                                          item?._id,
                                          dataIndex
                                        );
                                      },
                                    },
                                    {
                                      label: "Not Recommended",
                                      command: () => {
                                        createActivity(
                                          "Pending -> Not Recommended",
                                          item?.userVerificationRequestId,
                                          item?._id
                                        );
                                        updateCheckStatus(
                                          "Not Recommended",

                                          item?._id,
                                          dataIndex
                                        );
                                      },
                                    },
                                    {
                                      label: "Failed",
                                      command: () => {
                                        createActivity(
                                          "Pending -> Failed",
                                          item?.userVerificationRequestId,
                                          item?._id
                                        );
                                        updateCheckStatus(
                                          "Failed",

                                          item?._id,
                                          dataIndex
                                        );
                                      },
                                    },
                                    {
                                      label: "Could Not Verified",
                                      command: () => {
                                        createActivity(
                                          "Pending -> Could Not Verified",
                                          item?.userVerificationRequestId,
                                          item?._id
                                        );
                                        updateCheckStatus(
                                          "Could Not Verified",

                                          item?._id,
                                          dataIndex
                                        );
                                      },
                                    },
                                  ]}
                                />
                                <div className="ml-[20px]">
                                  {getSeverity(item?.police?.status)}
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    </>
                  );
                }
                return (
                  flag === "true" && (
                    <>
                      <div className="flex flex-row justify-between mb-[10px]">
                        <div className="flex justify-between">
                          {process.env.REACT_APP_SHOW_PAMAC_COLUMNS ===
                            "true" && (
                            <SplitButton
                              label="Update Status"
                              icon="pi pi-check"
                              model={[
                                {
                                  label: "Major Discrepancy",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Major Discrepancy",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Major Discrepancy",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Minor Discrepancy",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Minor Discrepancy",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Minor Discrepancy",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Verified Clear",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Verified Clear",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Verified Clear",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Insufficiency",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Insufficiency",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Insufficiency",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Unable to Verify",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Unable to Verify",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Unable to Verify",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Stop Check",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Stop Check",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Stop Check",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Positive",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Positive",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Positive",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Negative",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Negative",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Negative",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Recommended",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Recommended",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Recommended",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Not Recommended",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Not Recommended",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Not Recommended",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Failed",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Failed",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Failed",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Could Not Verified",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Could Not Verified",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Could Not Verified",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                              ]}
                            />
                          )}
                          <div className="ml-[20px]">
                            {getSeverity(item?.police?.status)}
                          </div>
                        </div>
                      </div>
                    </>
                  )
                );
              })()}

              {process.env.REACT_APP_IS_PAMAC_REPORT_ENABLED === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="photo">
                        Photo{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG, PNG, PDF less than 5MB)
                        </span>
                      </label>
                      {item?.police?.photo?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="photo[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          uploadHandler={(e) => handleFileUpload(e, "photo")}
                          itemTemplate={customItemTemplate}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.police?.photo || []).map(
                          (fileUrl, reportIndex) => {
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();
                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );
                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "photo" // Correct type passed here
                                    )
                                  }
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "photo"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {photoMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={photoMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={photoMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                    {user.role !== "user" && (
                      <>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Photo Remark
                          </label>
                          <Dropdown
                            value={item?.verificationDetails?.photoRemark || ""}
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "text",
                                "verificationDetails"
                              )
                            }
                            options={remarkStatus}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select"
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="photoRemark"
                            name="photoRemark"
                          />
                        </div>
                        <div className="col-md-4">
                          <label
                            className="small mb-1"
                            htmlFor="entityName"
                          ></label>
                          {item?.verificationDetails?.photoRemark ===
                            "Not Verified" && (
                            <InputText
                              className="form-control"
                              type="text"
                              name="photoReason"
                              placeholder="Enter details"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "text", "reason")
                              }
                              value={item?.reason?.photoReason || ""}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="sign">
                        Sign{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG, PNG, PDF less than 5MB)
                        </span>
                      </label>
                      {item?.police?.sign?.length === 0 || editable === true ? (
                        <FileUpload
                          name="sign[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          uploadHandler={(e) => handleFileUpload(e, "sign")}
                          itemTemplate={customItemTemplate}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.police?.sign || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "sign"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "sign"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {signMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={signMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={signMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                    {user.role !== "user" && (
                      <>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Sign Remark
                          </label>
                          <Dropdown
                            value={item?.verificationDetails?.signRemark || ""}
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "text",
                                "verificationDetails"
                              )
                            }
                            options={remarkStatus}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select"
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="signRemark"
                            name="signRemark"
                          />
                        </div>
                        <div className="col-md-4">
                          <label
                            className="small mb-1"
                            htmlFor="entityName"
                          ></label>
                          {item?.verificationDetails?.signRemark ===
                            "Not Verified" && (
                            <InputText
                              className="form-control"
                              type="text"
                              name="signReason"
                              placeholder="Enter details"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "text", "reason")
                              }
                              value={item?.reason?.signReason || ""}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="addressUpload">
                        Address Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG, PNG, PDF less than 5MB)
                        </span>
                      </label>
                      {item?.police?.addressUpload?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="addressUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          uploadHandler={(e) =>
                            handleFileUpload(e, "addressUpload")
                          }
                          itemTemplate={customItemTemplate}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.police?.addressUpload || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "addressUpload"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "address"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {addressMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={
                                          addressMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={
                                          addressMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                    {user.role !== "user" && (
                      <>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Address Upload Remark
                          </label>
                          <Dropdown
                            value={
                              item?.verificationDetails?.addressUploadRemark ||
                              ""
                            }
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "text",
                                "verificationDetails"
                              )
                            }
                            options={remarkStatus}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select"
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="addressUploadRemark"
                            name="addressUploadRemark"
                          />
                        </div>
                        <div className="col-md-4">
                          <label
                            className="small mb-1"
                            htmlFor="entityName"
                          ></label>
                          {item?.verificationDetails?.addressUploadRemark ===
                            "Not Verified" && (
                            <InputText
                              className="form-control"
                              type="text"
                              name="addressUploadReason"
                              placeholder="Enter details"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "text", "reason")
                              }
                              value={item?.reason?.addressUploadReason || ""}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="photoId">
                        Photo ID{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG, PNG, PDF less than 5MB)
                        </span>
                      </label>
                      {item?.police?.photoId?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="photoId[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          uploadHandler={(e) => handleFileUpload(e, "photoId")}
                          itemTemplate={customItemTemplate}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.police?.photoId || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "photoId"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "photoId"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {photoIdMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={
                                          photoIdMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={
                                          photoIdMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                    {user.role !== "user" && (
                      <>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Photo ID Remark
                          </label>
                          <Dropdown
                            value={
                              item?.verificationDetails?.photoIdRemark || ""
                            }
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "text",
                                "verificationDetails"
                              )
                            }
                            options={remarkStatus}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select"
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="photoIdRemark"
                            name="photoIdRemark"
                          />
                        </div>
                        <div className="col-md-4">
                          <label
                            className="small mb-1"
                            htmlFor="entityName"
                          ></label>
                          {item?.verificationDetails?.photoIdRemark ===
                            "Not Verified" && (
                            <InputText
                              className="form-control"
                              type="text"
                              name="photoIdReason"
                              placeholder="Enter details"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "text", "reason")
                              }
                              value={item?.reason?.photoIdReason || ""}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="dateOfBirth">
                        Date of Birth{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG, PNG, PDF less than 5MB)
                        </span>
                      </label>
                      {item?.police?.dateOfBirth?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="dateOfBirth[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          uploadHandler={(e) =>
                            handleFileUpload(e, "dateOfBirth")
                          }
                          itemTemplate={customItemTemplate}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.police?.dateOfBirth || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "dateOfBirth"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "dob"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {dobMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={dobMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={dobMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                    {user.role !== "user" && (
                      <>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Date of Birth Remark
                          </label>
                          <Dropdown
                            value={item?.verificationDetails?.dobRemark || ""}
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "text",
                                "verificationDetails"
                              )
                            }
                            options={remarkStatus}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select"
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="dobRemark"
                            name="dobRemark"
                          />
                        </div>
                        <div className="col-md-4">
                          <label
                            className="small mb-1"
                            htmlFor="entityName"
                          ></label>
                          {item?.verificationDetails?.dobRemark ===
                            "Not Verified" && (
                            <InputText
                              className="form-control"
                              type="text"
                              name="dobReason"
                              placeholder="Enter details"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "text", "reason")
                              }
                              value={item?.reason?.dobReason || ""}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {user.role !== "user" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Address
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="address"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "police"
                              )
                            }
                            value={item?.police?.address || ""}
                          />
                        </div>
                        {user.role !== "user" && (
                          <>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="entityName"
                              >
                                Remark
                              </label>
                              <Dropdown
                                value={
                                  item?.verificationDetails?.addressRemark || ""
                                }
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols",
                                    "verificationDetails"
                                  )
                                }
                                options={remarkStatus}
                                optionLabel="name"
                                optionValue="name"
                                placeholder="Select"
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                                className="w-full md:w-14rem"
                                inputId="addressRemark"
                                name="addressRemark"
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="entityName"
                              ></label>
                              {item?.verificationDetails?.addressRemark ===
                                "Not Verified" && (
                                <InputText
                                  className="form-control"
                                  type="text"
                                  name="addressReason"
                                  placeholder="Enter details"
                                  onChange={(e) =>
                                    onInputChange(
                                      e,
                                      dataIndex,
                                      "alphanumericWithAllowedSymbols",
                                      "reason"
                                    )
                                  }
                                  value={item?.reason?.addressReason || ""}
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Police Station
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="policeStation"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "police"
                              )
                            }
                            value={item?.police?.policeStation || ""}
                          />
                        </div>
                        {user.role !== "user" && (
                          <>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="entityName"
                              >
                                Remark
                              </label>
                              <Dropdown
                                value={
                                  item?.verificationDetails
                                    ?.policeStationRemark || ""
                                }
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols",
                                    "verificationDetails"
                                  )
                                }
                                options={remarkStatus}
                                optionLabel="name"
                                optionValue="name"
                                placeholder="Select"
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                                className="w-full md:w-14rem"
                                inputId="policeStationRemark"
                                name="policeStationRemark"
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="entityName"
                              ></label>
                              {item?.verificationDetails
                                ?.policeStationRemark === "Not Verified" && (
                                <InputText
                                  className="form-control"
                                  type="text"
                                  name="policeStationReason"
                                  placeholder="Enter details"
                                  onChange={(e) =>
                                    onInputChange(
                                      e,
                                      dataIndex,
                                      "alphanumericWithAllowedSymbols",
                                      "reason"
                                    )
                                  }
                                  value={
                                    item?.reason?.policeStationReason || ""
                                  }
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>

                      <div className="row my-2">
                        <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <label className="small mb-1" htmlFor="reportNumber">
                            Criminal Record Status
                          </label>
                          <InputText
                            className="form-control"
                            type="int"
                            name="criminalRecordStatus"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "police"
                              )
                            }
                            value={item?.police?.criminalRecordStatus || ""}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <label className="small mb-1" htmlFor="reportNumber">
                            Number of the year covered in the Police
                            Verification
                          </label>
                          <InputText
                            className="form-control"
                            type="int"
                            name="numberOfYear"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "police"
                              )
                            }
                            value={item?.police?.numberOfYear || ""}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <label className="small mb-1" htmlFor="reportNumber">
                            Additional Comments
                          </label>
                          <InputText
                            className="form-control"
                            type="int"
                            name="additionalComments"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "police"
                              )
                            }
                            value={item?.police?.additionalComments || ""}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <label
                          className="small mb-1"
                          htmlFor="modeOfVerification"
                        >
                          Mode of Verification
                        </label>

                        <Dropdown
                          value={item?.police?.modeOfVerification || ""}
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "police"
                            )
                          }
                          options={modeOfVerification}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Select"
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          className="w-full md:w-14rem"
                          inputId="modeOfVerification"
                          name="modeOfVerification"
                        />
                      </div>

                      <div className="row my-2">
                        <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <label className="small mb-1" htmlFor="reportNumber">
                            Name and Designation of the Verifier
                          </label>
                          <InputText
                            className="form-control"
                            type="int"
                            name="nameAndDesignationOfTheVerifier"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "police"
                              )
                            }
                            value={
                              item?.police?.nameAndDesignationOfTheVerifier ||
                              ""
                            }
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <label className="small mb-1" htmlFor="reportNumber">
                            Contact Detail of the Verifier
                          </label>
                          <InputText
                            className="form-control"
                            type="int"
                            name="contactDetailsOfTheVerifier"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "police"
                              )
                            }
                            value={
                              item?.police?.contactDetailsOfTheVerifier || ""
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {/* ----------------------------------------------------------------------------- */}
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.police?.reportUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          itemTemplate={customItemTemplate}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.police?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  {localStorageData.role !== "user" && (
                    <>
                      <div className="col-md-8">
                        <label className="small mb-1" htmlFor="remark">
                          Remark
                        </label>
                        <textarea
                          className="form-control h-[110px]"
                          name="remark"
                          onChange={(e) => {
                            // setRemarkForActivity(e.target.value);
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "police"
                            );
                          }}
                          value={item?.police?.remark || ""}
                        />
                      </div>

                      <div className="col-md-12 my-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Upload Proof{" "}
                          <span className="opacity-70">
                            (Please Choose JPEG,PNG Only)
                          </span>
                        </label>
                        {item?.police?.uploadProofUrl?.length === 0 && (
                          <FileUpload
                            name="fileUpload[]"
                            auto
                            multiple
                            accept="image/*"
                            customUpload={true}
                            maxFileSize={10000000}
                            uploadHandler={(e) =>
                              handleFileUpload(e, "uploadProof")
                            }
                            itemTemplate={customItemTemplate}
                          />
                        )}
                        <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                          {(item?.police?.uploadProofUrl || []).map(
                            (fileUrl, proofIndex) => {
                              // Extract file name from the URL
                              const fileName = fileUrl.split("/").pop();
                              const fileExtension = fileName
                                .split(".")
                                .pop()
                                .toLowerCase();

                              const isImage = ["jpeg", "png"].includes(
                                fileExtension
                              );

                              return (
                                <li
                                  key={proofIndex}
                                  className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                >
                                  <div
                                    role="presentation"
                                    onClick={(e) =>
                                      removeImg(
                                        e,
                                        item._id,
                                        item,
                                        proofIndex,
                                        "uploadProof"
                                      )
                                    } // Pass the `item` to removeImg
                                    className="cursor-pointer"
                                  >
                                    <i
                                      className="pi pi-times"
                                      style={{ color: "red" }}
                                    ></i>
                                  </div>
                                  <Button
                                    label="Download"
                                    onClick={(e) =>
                                      getImg(
                                        e,
                                        fileName,
                                        dataIndex,
                                        proofIndex,
                                        "proof"
                                      )
                                    }
                                    className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                  />
                                  <div className="text-center mt-2">
                                    {isImage &&
                                      proofImage[dataIndex]?.[proofIndex] && (
                                        <a
                                          href={
                                            proofImage[dataIndex][proofIndex]
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          download={`file_${dataIndex}_${proofIndex}`}
                                        >
                                          View Image
                                        </a>
                                      )}
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </>
                  )}

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" && item?.police?.status === "Pending" ? (
                      item?.police?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {/* {item?.police?.status === "Pending" ? ( */}
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateUANDetails(e, item._id, dataIndex);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.police?.status)}</div>
                    </div>
                    {/* ) : (
                      <div>{getSeverity(item?.police?.status)}</div>
                    )} */}
                  </div>
                </form>
              )}
            </div>
          </Card>

          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
              checkName="Police"
            />
          </div>
          {localStorageData?.role === "user" && (
            <ConfirmDialog visible={isModalOpen} />
          )}
        </>
      ))}
    </>
  );
};
