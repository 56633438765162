import React, { useEffect, useState } from "react";

import {
  StyleSheet,
  Text,
  View,
  Image,
  Page,
  Document,
  PDFViewer,
} from "@react-pdf/renderer";

import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import Vleader_logo from "../../../assets/images/VLeader_Logo.jpg";
import Vleader_footer from "../../../assets/images/Vleader_Footer.png";
import { VleaderEducationDetailCheck } from "./VleaderEducationDetailCheck.jsx";
import { VleaderEmploymentDetailCheck } from "./VleaderEmploymentDetailCheck.jsx";
import { VleaderAddressDetailCheck } from "./VleaderAddressDetailCheck.jsx";
import { VleaderReferenceDetailCheck } from "./VleaderReferenceDetailCheck.jsx";
import { VleaderAadharDetailCheck } from "./VleaderAadharDetailCheck.jsx";
import { VleaderPanDetailCheck } from "./VleaderPanDetailCheck.jsx";
import { VleaderCriminalDetailCheck } from "./VleaderCriminalDetailCheck.jsx";
import { VleaderCourtDetailCheck } from "./VleaderCourtDetailCheck.jsx";
import { VleaderGlobalDatabaseDetailCheck } from "./VleaderGlobalDatabaseDetailCheck.jsx";
import { VleaderCreditDetailCheck } from "./VleaderCreditDetailCheck.jsx";

const getStatusColor = (status) => {
  switch (status) {
    case "Major Discrepancy":
      return "red";
    case "Minor Discrepancy":
      return "orange";
    case "Verified Clear":
      return "green";
    case "Insufficiency":
      return "#859bed";
    case "Unable to Verify":
      return "orange";
    case "Stop Check":
      return "gray";
    case "Rejected":
      return "red";
    case "Approved":
      return "green";
    case "Completed":
      return "green";
    case "InProgress":
      return "#859bed";
    case "Pending":
      return "orange";
    case "BcaApproval":
      return "green";
    case "BcaRejection":
      return "red";
    case "Positive":
      return "green";
    case "Negative":
      return "red";
    case "Recommended":
      return "yellow";
    case "Not Recommended":
      return "#859bed";
    case "Failed":
      return "red";
    case "Could Not Verified":
      return "red";
    default:
      return "yellow";
  }
};

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 75, // Increased top padding for header
    paddingBottom: 80, // Increased bottom padding for footer
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  headerContainer: {
    position: "absolute",
    top: 5,
    left: 40,
    right: 40,
  },
  footerContainer: {
    position: "absolute",
    bottom: 0,
    left: 40,
    right: 40,
  },
  spaceBetween: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#3E3E3E",
  },

  titleContainer: { flexDirection: "row", marginTop: 24 },

  logo: { width: 100, height: 40 },
  // heading: {
  //   textAlign: "center",
  //   marginTop: 40,
  //   fontSize: 14,
  //   fontWeight: "bold",
  //   textDecoration: "underline",
  // },
  headingContainer: {
    backgroundColor: "#d3d3d3",
    border: "1px solid black",
    padding: 2,
    width: "100%",
    textAlign: "center",
    marginTop: 40,
  },
  heading: {
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    textDecoration: "underline",
  },

  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: 30,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  labelCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontWeight: "bold", // Bold text for labels
    fontSize: 8,
    backgroundColor: "#d3d3d3", // Grey background for labels
  },

  classificationsRow: {
    flexDirection: "row",
    marginTop: 10,
  },
  statusCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
    color: "#fff",
    fontWeight: "bold",
  },
  statusText: {
    fontSize: 8,
    textAlign: "center",
    color: "black",
  },
  synopsis: {
    textAlign: "center",
    marginTop: 5,
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  synopsistableCell: {
    flex: 1, // Normal cell width
    border: "1px solid black",
    textAlign: "left",
    padding: 5,
    fontSize: 10,
  },
  footer: {
    position: "absolute",
    height: "90px",
    bottom: 30,
    left: 0,
    right: 0,
  },
});

const checksStyle = StyleSheet.create({
  checksContainer: {
    marginTop: "20px",
  },
});

const sypnosisStyles = StyleSheet.create({
  container: {
    width: "100%",
    marginTop: 10,
    marginBottom: 40,
    minHeight: 550,
  },

  section: {
    border: "1px solid black",
    marginBottom: 10,
  },

  sectionHeader: {
    backgroundColor: "#d3d3d3",
    padding: 8,
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
    borderBottom: "1px solid black",
  },

  row: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid black",
  },

  cell: {
    flex: 1,
    padding: 8,
    fontSize: 10,
    textAlign: "center",
    borderRight: "1px solid black",
  },
});

const disclaimer = StyleSheet.create({
  disclaimerContainer: {
    position: "absolute",
    bottom: 0,
    left: 40,
    right: 40,
  },
  disclaimer: {
    position: "absolute",
    // height: "90px",
    bottom: 200,
    left: 0,
    right: 0,
  },
});

const Header = () => (
  <View style={styles.headerContainer} fixed>
    <CompanyTitle />
  </View>
);
const Footer = () => (
  <View style={styles.footerContainer} fixed>
    <Image style={styles.footer} src={Vleader_footer} />
  </View>
);

const CompanyTitle = () => (
  <View style={styles.titleContainer} fixed>
    <View style={styles.spaceBetween}>
      <Text style={styles.reportTitle}></Text>
      <Image style={styles.logo} src={Vleader_logo} />
    </View>
  </View>
);
const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const CurrentDate = () => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

const GeneralInfo = ({ verificationInfo }) => (
  <View style={styles.table}>
    {/* Row 1 */}
    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Name of Client</Text>
      <Text
        style={{
          width: "75%",
          border: "0.5px solid black",
          textAlign: "center",
        }}
      >
        {verificationInfo?.companyInfo?.name}
      </Text>
    </View>

    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Name of the Candidate</Text>
      <Text
        style={{
          width: "75%",
          textAlign: "center",
          border: "0.5px solid black",
        }}
      >
        {`${verificationInfo?.userInfo?.firstName} ${verificationInfo?.userInfo?.lastName}`}
      </Text>
    </View>

    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Client Emp ID</Text>
      <Text style={{ width: "75%", textAlign: "center" }}>
        {verificationInfo?.userInfo?.clientEmpId}
      </Text>
    </View>

    {/* Row 2 */}
    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Employee Id</Text>
      <Text style={styles.tableCell}>
        {verificationInfo?.userInfo?.referenceNumber || "NA"}
      </Text>
      <Text style={styles.labelCell}>Date Case Received</Text>
      <Text style={styles.tableCell}>
        {formatDate(verificationInfo?.requestedDate)}
      </Text>
    </View>

    {/* Row 3 */}
    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Vleader Reference Number</Text>
      <Text style={styles.tableCell}>
        {" "}
        {`${process.env.REACT_APP_REFERENCE_TITLE}-${verificationInfo?.case}`}
      </Text>
      <Text style={styles.labelCell}>Insufficiency Fulfilled Date</Text>
      <Text style={styles.tableCell}>***</Text>
    </View>

    {/* Row 4 */}
    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Insufficiency Raised Date</Text>
      <Text style={styles.tableCell}>*****</Text>
      <Text style={styles.labelCell}>Date Report Sent</Text>
      <Text style={styles.tableCell}>{CurrentDate()}</Text>
    </View>

    {/* Row 5 */}
    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Re-Verification Date</Text>
      <Text style={styles.tableCell}>***</Text>
      <Text style={styles.labelCell}>Report Status</Text>
      <Text
        style={[
          styles.tableCell,
          {
            backgroundColor: getStatusColor(verificationInfo?.status),
            color: "black",
          },
        ]}
      >
        {verificationInfo?.status}
      </Text>
    </View>

    {/* Row 6 */}
    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Date of Birth</Text>
      <Text style={{ width: "75%", textAlign: "center" }}>
        {verificationInfo?.userInfo?.dateOfBirth
          ? formatDate(verificationInfo.userInfo.dateOfBirth)
          : "NA"}
      </Text>
    </View>

    {/* Classifications */}
    <View style={styles.tableRow}>
      <Text style={[styles.labelCell, { width: "100%", textAlign: "center" }]}>
        Classifications of ‘Report Status’
      </Text>
    </View>

    <View style={styles.tableRow}>
      <View style={[styles.statusCell, { backgroundColor: "green" }]}>
        <Text style={styles.statusText}>Clear</Text>
      </View>
      <View style={[styles.statusCell, { backgroundColor: "yellow" }]}>
        <Text style={styles.statusText}>Minor Discrepancy</Text>
      </View>
      <View style={[styles.statusCell, { backgroundColor: "red" }]}>
        <Text style={styles.statusText}>Discrepant</Text>
      </View>
      <View style={[styles.statusCell, { backgroundColor: "yellow" }]}>
        <Text style={styles.statusText}>
          Inaccessible for verification / Inputs required
        </Text>
      </View>
    </View>
  </View>
);

const Disclaimer = () => {
  return (
    <View style={disclaimer.disclaimerContainer} fixed>
      <Text style={disclaimer.disclaimer}>
        Disclaimer: This reports only sets out information obtained from
        investigations conducted by Vleader verification Services. The company
        does not provide any opinion in respect to the entities or individuals
        on whom these investigations have been conducted. The information inclu
        ded in this report has been primarily collected from public sources and
        individual opinions, thus it is difficult to verify all aspects of the
        information obtained. Vleader verification Services makes no
        representation or warranties with respect to the contents or use of this
        report, and specifically disclaims any express or implied warranties or
        usefulness for any particular purpose of this report. This report cannot
        be used for litigation purposes without the consent of Vleader
        verification Services. Vleader takes no responsibility for the
        consequences resulting from decisions based on information included in
        this report. Vleader verification Services reserves the right to change
        or revise this report at any time. This report cannot be used for
        litigation purposes without th e consent of Vleader verification
        Services
      </Text>
    </View>
  );
};

const Synopsis = ({
  selectedChecks,
  educationData,
  experienceData,
  addressData,
  referenceData,
  aadharData,
  panData,
  criminalData,
  courtData,
  globalDatabaseData,
  creditData,
}) => {
  return (
    <View style={sypnosisStyles.container}>
      {/* Education Verification */}
      <View wrap={false}>
        {selectedChecks?.education && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Educational Verification
            </Text>
            {educationData?.educationInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  Education Verification Report -{" "}
                  {item?.education?.unversityName}
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.education?.status) },
                  ]}
                >
                  {item?.education?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>

      {/* Employment Verification */}
      <View wrap={false}>
        {selectedChecks?.experience && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Employment Verification
            </Text>
            {experienceData?.experienceInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  Previous Employment Verification Report -{" "}
                  {item?.experience?.organizationName}
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.experience?.status) },
                  ]}
                >
                  {item?.experience?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      {/* Address Verification */}
      <View wrap={false}>
        {selectedChecks?.address && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Address Verification
            </Text>
            {addressData?.addressInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  Address Verification Report - {item?.address?.typeOfAddress}
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.address?.status) },
                  ]}
                >
                  {item?.address?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      {/* Reference Check Verification */}
      <View wrap={false}>
        {selectedChecks?.reference && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Reference Check Verification
            </Text>
            {referenceData?.referenceInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  Reference Check Report - {item?.reference?.refereeName}
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.reference?.status) },
                  ]}
                >
                  {item?.reference?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      {/* Aadhar Verification */}
      <View wrap={false}>
        {selectedChecks?.aadhar && aadharData?.aadharInfo?.identity && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Aadhar Verification
            </Text>
            <View style={sypnosisStyles.row}>
              <Text style={sypnosisStyles.cell}>
                Aadhar Verification Report -{" "}
                {aadharData?.aadharInfo?.identity?.cardNumber}
              </Text>
              <Text
                style={[
                  sypnosisStyles.cell,
                  {
                    color: getStatusColor(
                      aadharData?.aadharInfo?.identity?.status
                    ),
                  },
                ]}
              >
                {aadharData?.aadharInfo?.identity?.status}
              </Text>
            </View>
          </View>
        )}
      </View>
      {/* PAN Verification */}
      <View wrap={false}>
        {selectedChecks?.pan && panData?.panInfo?.identity && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>PAN Verification</Text>
            <View style={sypnosisStyles.row}>
              <Text style={sypnosisStyles.cell}>
                PAN Verification Report -{" "}
                {panData?.panInfo?.identity?.panNumber}
              </Text>
              <Text
                style={[
                  sypnosisStyles.cell,
                  {
                    color: getStatusColor(panData?.panInfo?.identity?.status),
                  },
                ]}
              >
                {panData?.panInfo?.identity?.status}
              </Text>
            </View>
          </View>
        )}
      </View>
      {/* Criminal Check Verification */}
      <View wrap={false}>
        {selectedChecks?.criminal && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Criminal Check Verification
            </Text>
            {criminalData?.criminalInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  Criminal Check Report - {item?.criminal?.criminalForm}
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.criminal?.status) },
                  ]}
                >
                  {item?.criminal?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      {/* Court Check Verification */}
      <View wrap={false}>
        {selectedChecks?.court && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Court Check Verification
            </Text>
            {courtData?.courtInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  {item?.court?.courtSelect}
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.court?.status) },
                  ]}
                >
                  {item?.court?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      {/* Global Database Check Verification */}
      <View wrap={false}>
        {selectedChecks?.globalDatabase && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Global Database Check Verification
            </Text>
            {globalDatabaseData?.globalDatabaseInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  Global Database Check Report
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.globalDatabase?.status) },
                  ]}
                >
                  {item?.globalDatabase?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      {/* Credit Check Verification */}
      <View wrap={false}>
        {selectedChecks?.credit && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Credit Check Verification
            </Text>
            {creditData?.creditInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>Credit Check Report</Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.credit?.status) },
                  ]}
                >
                  {item?.credit?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
    </View>
  );
};

// Report document component
const ReportDocument = ({
  verificationInfo,
  selectedChecks,
  educationData,
  experienceData,
  addressData,
  referenceData,
  aadharData,
  panData,
  criminalData,
  courtData,
  globalDatabaseData,
  creditData,
}) => {
  // Get all enabled pages based on selectedChecks
  const enabledPages = [
    { type: "basic", enabled: true },
    { type: "summary", enabled: true },
    { type: "education", enabled: selectedChecks?.education && educationData },
    {
      type: "experience",
      enabled: selectedChecks?.experience && experienceData,
    },
    { type: "address", enabled: selectedChecks?.address && addressData },
    { type: "reference", enabled: selectedChecks?.reference && referenceData },
    { type: "aadhar", enabled: selectedChecks?.aadhar && aadharData },
    { type: "pan", enabled: selectedChecks?.pan && panData },
    { type: "criminal", enabled: selectedChecks?.criminal && criminalData },
    { type: "court", enabled: selectedChecks?.court && courtData },
    {
      type: "globalDatabase",
      enabled: selectedChecks?.globalDatabase && globalDatabaseData,
    },
    { type: "credit", enabled: selectedChecks?.credit && creditData },
  ];

  // Count total enabled pages
  const totalPages = enabledPages.filter((page) => page.enabled).length;

  // Track current page number
  let currentPage = 0;

  // End of Report component
  const EndOfReport = () => (
    <View
      style={{
        // // position: "absolute",
        // bottom: 1,
        // left: 0,
        // right: 0,
        textAlign: "center",
      }}
      // fixed
    >
      <Text style={{ fontSize: 14, fontWeight: "bold" }}>-End of Report-</Text>
    </View>
  );
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header />
        {/* <View style={styles.headingContainer}>
        <Text style={styles.heading}>Final Background Report</Text>
      </View> */}
        <GeneralInfo verificationInfo={verificationInfo} />
        <Disclaimer />
        {/* <Text style={styles.synopsis}>Executive Summary</Text>
      <Synopsis
        selectedChecks={selectedChecks}
        educationData={educationData}
        experienceData={experienceData}
        addressData={addressData}
        referenceData={referenceData}
        aadharData={aadharData}
        panData={panData}
        criminalData={criminalData}
        courtData={courtData}
        globalDatabaseData={globalDatabaseData}
        creditData={creditData}
      /> */}
        <View> </View>
        {++currentPage === totalPages && <EndOfReport />}
        <Footer />
      </Page>
      <Page size="A4" style={styles.page}>
        <Header />
        <Text style={styles.synopsis}>Executive Summary</Text>
        <Synopsis
          selectedChecks={selectedChecks}
          educationData={educationData}
          experienceData={experienceData}
          addressData={addressData}
          referenceData={referenceData}
          aadharData={aadharData}
          panData={panData}
          criminalData={criminalData}
          courtData={courtData}
          globalDatabaseData={globalDatabaseData}
          creditData={creditData}
        />
        <View> </View>
        {++currentPage === totalPages && <EndOfReport />}

        <Footer />
      </Page>
      {selectedChecks?.education && educationData && (
        <Page size="A4" style={styles.page}>
          <Header />
          <View style={checksStyle.checksContainer} wrap={true}>
            {selectedChecks.education && educationData && (
              <>{educationData.renderEducationDetails()}</>
            )}
          </View>
          <View> </View>
          {++currentPage === totalPages && <EndOfReport />}
          <Footer />
        </Page>
      )}
      {selectedChecks?.experience && experienceData && (
        <Page size="A4" style={styles.page}>
          <Header />
          <View style={checksStyle.checksContainer} wrap={true}>
            {selectedChecks.experience && experienceData && (
              <>{experienceData.renderExperienceDetails()}</>
            )}
          </View>
          <View> </View>
          {++currentPage === totalPages && <EndOfReport />}
          <Footer />
        </Page>
      )}
      {selectedChecks?.address && addressData && (
        <Page size="A4" style={styles.page}>
          <Header />
          <View style={checksStyle.checksContainer} wrap={true}>
            {selectedChecks.address && addressData && (
              <>{addressData.renderAddressDetails()}</>
            )}
          </View>
          <View> </View>
          {++currentPage === totalPages && <EndOfReport />}
          <Footer />
        </Page>
      )}

      {selectedChecks?.reference && referenceData && (
        <Page size="A4" style={styles.page} wrap={true}>
          <Header />
          <View style={checksStyle.checksContainer} wrap={true}>
            {selectedChecks.reference && referenceData && (
              <>{referenceData.renderReferenceDetails()}</>
            )}
          </View>
          <View> </View>
          {++currentPage === totalPages && <EndOfReport />}
          <Footer />
        </Page>
      )}

      {selectedChecks?.aadhar && aadharData && (
        <Page size="A4" style={styles.page} wrap={true}>
          <Header />
          <View style={checksStyle.checksContainer} wrap={true}>
            {selectedChecks.aadhar && aadharData && (
              <>{aadharData.renderAadharDetails()}</>
            )}
          </View>
          <View> </View>
          {++currentPage === totalPages && <EndOfReport />}
          <Footer />
        </Page>
      )}

      {selectedChecks?.pan && panData && (
        <Page size="A4" style={styles.page} wrap={true}>
          <Header />
          <View style={checksStyle.checksContainer} wrap={true}>
            {selectedChecks.pan && panData && <>{panData.renderPanDetails()}</>}
          </View>
          <View> </View>
          {++currentPage === totalPages && <EndOfReport />}
          <Footer />
        </Page>
      )}

      {selectedChecks?.criminal && criminalData && (
        <Page size="A4" style={styles.page} wrap={true}>
          <Header />
          <View style={checksStyle.checksContainer} wrap={true}>
            {selectedChecks.criminal && criminalData && (
              <>{criminalData.renderCriminalDetails()}</>
            )}
          </View>
          <View> </View>
          {++currentPage === totalPages && <EndOfReport />}
          <Footer />
        </Page>
      )}

      {selectedChecks?.court && courtData && (
        <Page size="A4" style={styles.page} wrap={true}>
          <Header />
          <View style={checksStyle.checksContainer} wrap={true}>
            {selectedChecks.court && courtData && (
              <>{courtData.renderCourtDetails()}</>
            )}
          </View>
          <View> </View>
          {++currentPage === totalPages && <EndOfReport />}
          <Footer />
        </Page>
      )}

      {selectedChecks?.globalDatabase && globalDatabaseData && (
        <Page size="A4" style={styles.page} wrap={true}>
          <Header />
          <View style={checksStyle.checksContainer} wrap={true}>
            {selectedChecks.globalDatabase && globalDatabaseData && (
              <>{globalDatabaseData.renderGlobalDatabaseDetails()}</>
            )}
          </View>
          <View> </View>
          {++currentPage === totalPages && <EndOfReport />}
          <Footer />
        </Page>
      )}

      {selectedChecks?.credit && creditData && (
        <Page size="A4" style={styles.page} wrap={true}>
          <Header />
          <View style={checksStyle.checksContainer} wrap={true}>
            {selectedChecks.credit && creditData && (
              <>{creditData.renderCreditDetails()}</>
            )}
          </View>
          <View> </View>
          {++currentPage === totalPages && <EndOfReport />}
          <Footer />
        </Page>
      )}
    </Document>
  );
};

// Main component
export const VleaderReport = () => {
  const [selectedChecks, setSelectedChecks] = useState({
    education: false,
    experience: false,
    address: false,
    reference: false,
    aadhar: false,
    pan: false,
    criminal: false,
    court: false,
    globalDatabase: false,
    credit: false,
  });
  const location = useLocation();
  const [isPreview, setIsPreview] = useState(false);
  const [verificationInfo, setVerificationInfo] = useState(null);
  const [educationData, setEducationData] = useState(null);
  const [experienceData, setExperienceData] = useState(null);
  const [addressData, setAddressData] = useState(null);
  const [referenceData, setReferenceData] = useState(null);
  const [aadharData, setAadharData] = useState(null);
  const [panData, setPanData] = useState(null);
  const [criminalData, setCriminalData] = useState(null);
  const [courtData, setCourtData] = useState(null);
  const [globalDatabaseData, setGlobalDatabaseData] = useState(null);
  const [creditData, setCreditData] = useState(null);

  const getUserVerificationReqInfo = async (userVerificationReqId) => {
    await ApiService.get(`userVerificationRequest/${userVerificationReqId}`)
      .then((res) => {
        setVerificationInfo(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("VerificationInfor>>>>", verificationInfo);
  }, [verificationInfo]);

  useEffect(() => {
    getUserVerificationReqInfo(location?.state?.id);
  }, [location?.state?.id]);

  const handleEducationDataUpdate = (data) => {
    setEducationData(data);
  };

  useEffect(() => {
    console.log("DTATTATATT>>>>>", educationData);
  }, [educationData]);

  const handleExperienceDataUpdate = (data) => {
    setExperienceData(data);
  };

  const handleAddressDataUpdate = (data) => {
    setAddressData(data);
  };

  const handleReferenceDataUpdate = (data) => {
    setReferenceData(data);
  };

  const handleAadharDataUpdate = (data) => {
    setAadharData(data);
  };

  const handlePanDataUpdate = (data) => {
    setPanData(data);
  };

  const handleCriminalDataUpdate = (data) => {
    setCriminalData(data);
  };

  const handleCourtDataUpdate = (data) => {
    setCourtData(data);
  };

  const handleGlobalDatabadeDataUpdate = (data) => {
    setGlobalDatabaseData(data);
  };

  const handleCreditDataUpdate = (data) => {
    setCreditData(data);
  };

  const togglePreview = () => {
    setIsPreview(!isPreview);
  };

  return (
    <div>
      <h1>Generate Background Report</h1>
      <div>
        <style>
          {`
      .checkbox-container {
        display: flex; /* Enable flexbox for row layout */
        flex-wrap: wrap; /* Wrap items to the next line if needed */
        gap: 16px; /* Add spacing between checkboxes */
      }
    `}
        </style>
        {/* Options to select sections for interim report */}
        <div className="checkbox-container">
          {verificationInfo?.checks?.map((check) => {
            // Determine the label and state key dynamically
            const renderChecks = (() => {
              switch (check) {
                case "Education":
                  return [{ label: "Education", key: "education" }];
                case "Experience":
                  return [{ label: "Experience", key: "experience" }];
                case "Address":
                  return [{ label: "Address", key: "address" }];
                case "Reference":
                  return [{ label: "Reference", key: "reference" }];
                case "Identity":
                  // Render both Aadhar and Pan under Identity
                  return [
                    { label: "Aadhar", key: "aadhar" },
                    { label: "Pan", key: "pan" },
                  ];
                case "Criminal":
                  return [{ label: "Criminal", key: "criminal" }];
                case "Court":
                  return [{ label: "Court", key: "court" }];
                case "GlobalDatabase":
                  return [{ label: "Global Database", key: "globalDatabase" }];
                case "Credit":
                  return [{ label: "Credit", key: "credit" }];
                default:
                  return [];
              }
            })();

            // If no checks to render, skip
            if (!renderChecks.length) return null;

            // Render all relevant checkboxes for the current `check`
            return renderChecks.map(({ label, key }) => (
              <label key={key}>
                <input
                  type="checkbox"
                  checked={selectedChecks[key]}
                  onChange={() =>
                    setSelectedChecks((prev) => ({
                      ...prev,
                      [key]: !prev[key],
                    }))
                  }
                />{" "}
                {label}
              </label>
            ));
          })}
        </div>
      </div>

      <button
        className="w-[130px] h-[35px] text-[12px] text-white bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60 p-2 mt-4 ml-4"
        onClick={togglePreview}
      >
        {isPreview ? "Close Preview" : "Preview Report"}
      </button>
      <VleaderEducationDetailCheck onDataUpdate={handleEducationDataUpdate} />
      <VleaderEmploymentDetailCheck onDataUpdate={handleExperienceDataUpdate} />
      <VleaderAddressDetailCheck onDataUpdate={handleAddressDataUpdate} />
      <VleaderReferenceDetailCheck onDataUpdate={handleReferenceDataUpdate} />
      <VleaderAadharDetailCheck onDataUpdate={handleAadharDataUpdate} />
      <VleaderPanDetailCheck onDataUpdate={handlePanDataUpdate} />
      <VleaderCriminalDetailCheck onDataUpdate={handleCriminalDataUpdate} />
      <VleaderCourtDetailCheck onDataUpdate={handleCourtDataUpdate} />
      <VleaderGlobalDatabaseDetailCheck
        onDataUpdate={handleGlobalDatabadeDataUpdate}
      />
      <VleaderCreditDetailCheck onDataUpdate={handleCreditDataUpdate} />

      {/* PDF Preview */}
      {isPreview && (
        <div style={{ height: "500px", marginTop: "20px" }}>
          <PDFViewer width="100%" height="500px">
            <ReportDocument
              //   data={sampleData}
              verificationInfo={verificationInfo}
              selectedChecks={selectedChecks}
              educationData={educationData}
              experienceData={experienceData}
              addressData={addressData}
              referenceData={referenceData}
              aadharData={aadharData}
              panData={panData}
              criminalData={criminalData}
              courtData={courtData}
              globalDatabaseData={globalDatabaseData}
              creditData={creditData}
            />
          </PDFViewer>
        </div>
      )}
    </div>
  );
};

export default VleaderReport;
